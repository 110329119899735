html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

body {
  overflow-y: scroll;
}

/* Adjust scrollbar width to avoid layout shift */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Customize scrollbar appearance */
::-webkit-scrollbar-thumb {
  background-color: #2ac0e6;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #e5e7eb; /* Color of the track */
}

@import "react-datepicker/dist/react-datepicker.css";
@import "react-circular-progressbar/dist/styles.css";
@import "react-toastify/dist/ReactToastify.css";

.active-step::before,
.completed-step::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  border: 1px solid #2ac0e6;
  z-index: -1;
  background-color: #2ac0e6 !important;
}

.flex.items-start.relative:first-of-type::before {
  content: "";
  position: absolute;
  top: 0;
  left: 15.75px;
  transform: translateY(-50%);
  width: 8px; /* Adjust size of the dot */
  height: 8px; /* Adjust size of the dot */
  background-color: #2ac0e6; /* Color of the dot */
  border-radius: 50%; /* Shape the dot into a circle */
  z-index: 1; /* Ensure dot appears above the line */
}

.disbaled-step::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  border: 1px solid #d1d5db;
  z-index: -1;
}

.flex.items-start.relative:last-of-type::before {
  content: "▼";
  position: absolute;
  bottom: -2px;
  left: 13.75px;
  transform: translateY(-50%);
  width: 8px; /* Adjust size of the dot */
  height: 8px; /* Adjust size of the dot */
  border-radius: 50%; /* Shape the dot into a circle */
  z-index: 1; /* Ensure dot appears above the line */
  color: #d1d5db;
  font-size: 12px;
}

.datePicker.react-datepicker {
  font-family: "Libre Franklin", "Inter", "sans-serif";
}

.datePicker .react-datepicker__header {
  background-color: transparent;
  border: none;
}

/* Custom styles for the date picker */
.datePicker .react-datepicker,
.react-datepicker__week {
  border: 1px solid #e5e7eb; /* Add a border around the date picker */
  border-bottom: none;
}

/* Style for each individual date */
.datePicker .react-datepicker__day {
  border-right: 1px solid #e5e7eb; /* Add a border to the right of each date */
  margin: 0;
  padding: 0.5rem;
  height: 48px;
  width: 48px;
}

/* Style for the last row of dates */
.datePicker .react-datepicker__day:last-child {
  border: none; /* Remove the border from the last row of dates */
}

.datePicker .react-datepicker__week:last-child {
  border-bottom: 1px solid #e5e7eb;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: transparent !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3rem;
  margin: 0;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: transparent !important;
}
.react-datepicker__day--disabled div.customDay:hover,
.react-datepicker__month-text--disabled div.customDay:hover,
.react-datepicker__quarter-text--disabled div.customDay:hover,
.react-datepicker__year-text--disabled div.customDay:hover {
  color: #9ca3af !important;
  font-weight: 400 !important;
  background: #f9fafb !important;
}

ul li {
  margin-left: 1rem;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 34px;
}

.datePicker .customDay {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeOnlyPicker .datePicker .react-datepicker__time-container {
  width: 100%;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: auto;
  overflow-x: hidden;
  margin: 0;
  text-align: left;
  border-bottom-right-radius: 0.3rem;
}

.timeOnlyPicker .react-datepicker-popper {
  min-width: 50%;
  padding: 0 12px;
}

.timeOnlyPicker .react-datepicker__header--time {
  padding: 0;
  display: none;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 0;
  height: 36px;
  margin: 0;
  color: #111827;
  font-size: 14px;
  padding: 8px 24px;
  cursor: pointer;
  position: relative;
  text-transform: lowercase;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover,
.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #a8eaf9 !important;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover::after,
.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:focus::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi43MDcxIDUuMjkyODlDMTcuMDk3NiA1LjY4MzQyIDE3LjA5NzYgNi4zMTY1OCAxNi43MDcxIDYuNzA3MTFMOC43MDcxMSAxNC43MDcxQzguMzE2NTggMTUuMDk3NiA3LjY4MzQyIDE1LjA5NzYgNy4yOTI4OSAxNC43MDcxTDMuMjkyODkgMTAuNzA3MUMyLjkwMjM3IDEwLjMxNjYgMi45MDIzNyA5LjY4MzQyIDMuMjkyODkgOS4yOTI4OUMzLjY4MzQyIDguOTAyMzcgNC4zMTY1OCA4LjkwMjM3IDQuNzA3MTEgOS4yOTI4OUw4IDEyLjU4NThMMTUuMjkyOSA1LjI5Mjg5QzE1LjY4MzQgNC45MDIzNyAxNi4zMTY2IDQuOTAyMzcgMTYuNzA3MSA1LjI5Mjg5WiIgZmlsbD0iIzExMTgyNyIvPgo8L3N2Zz4K) !important;
  position: absolute;
  right: 13px;
}
.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:focus {
  background-color: #2ac0e6 !important;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:disabled,
.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled,
.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:hover,
.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:focus {
  color: #9ca3af;
  font-weight: 400;
  cursor: default;
  background-color: transparent !important;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: transparent !important;
}

.timeOnlyPicker
  .react-datepicker__time-list-item.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--disabled {
  font-weight: 600 !important;
}

.timeOnlyPicker .datePicker {
  padding: 0;
  width: 100%;
}

.timeOnlyPicker.onboardingTimePicker .react-datepicker-popper {
  min-width: 18%;
}

.timeOnlyPicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi43MDcxIDUuMjkyODlDMTcuMDk3NiA1LjY4MzQyIDE3LjA5NzYgNi4zMTY1OCAxNi43MDcxIDYuNzA3MTFMOC43MDcxMSAxNC43MDcxQzguMzE2NTggMTUuMDk3NiA3LjY4MzQyIDE1LjA5NzYgNy4yOTI4OSAxNC43MDcxTDMuMjkyODkgMTAuNzA3MUMyLjkwMjM3IDEwLjMxNjYgMi45MDIzNyA5LjY4MzQyIDMuMjkyODkgOS4yOTI4OUMzLjY4MzQyIDguOTAyMzcgNC4zMTY1OCA4LjkwMjM3IDQuNzA3MTEgOS4yOTI4OUw4IDEyLjU4NThMMTUuMjkyOSA1LjI5Mjg5QzE1LjY4MzQgNC45MDIzNyAxNi4zMTY2IDQuOTAyMzcgMTYuNzA3MSA1LjI5Mjg5WiIgZmlsbD0iIzBGQUNENyIvPgo8L3N2Zz4K);
  position: absolute;
  right: 13px;
}
.timeOnlyPicker
  .react-datepicker__time-list-item.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--disabled::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi43MDcxIDUuMjkyODlDMTcuMDk3NiA1LjY4MzQyIDE3LjA5NzYgNi4zMTY1OCAxNi43MDcxIDYuNzA3MTFMOC43MDcxMSAxNC43MDcxQzguMzE2NTggMTUuMDk3NiA3LjY4MzQyIDE1LjA5NzYgNy4yOTI4OSAxNC43MDcxTDMuMjkyODkgMTAuNzA3MUMyLjkwMjM3IDEwLjMxNjYgMi45MDIzNyA5LjY4MzQyIDMuMjkyODkgOS4yOTI4OUMzLjY4MzQyIDguOTAyMzcgNC4zMTY1OCA4LjkwMjM3IDQuNzA3MTEgOS4yOTI4OUw4IDEyLjU4NThMMTUuMjkyOSA1LjI5Mjg5QzE1LjY4MzQgNC45MDIzNyAxNi4zMTY2IDQuOTAyMzcgMTYuNzA3MSA1LjI5Mjg5WiIgZmlsbD0iI0QxRDVEQiIvPgo8L3N2Zz4K) !important;
  position: absolute;
  right: 13px;
}

.leftShadow {
  box-shadow: -2px 0px 8px -2px rgba(0, 0, 0, 0.1);
}
