@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-body, .modal {
    min-width: 378px;
}

@media (min-width: 768px) {
    .modal-body, .modal {
        min-width: 574px;
    }
    .modal.setWidth, .modal.setWidth .modal-body {
        width: 574px;
    }

    .modal.large, .modal.large .modal-body {
        min-width: 900px;
        max-width: 991px;
        overflow-y: auto;
    }
}

